<div class="row document-meta">
  <mat-accordion class="col-12">
    <mat-expansion-panel expanded class="cx-expansion-panel">
      <mat-expansion-panel-header>
        <h6>{{ 'TEMPLATE_EDITOR.DOCUMENT_METADATA.HEADING' | translate }}</h6>
      </mat-expansion-panel-header>

      <div class="row gap">
        <mat-form-field class="col-4">
          <mat-label>{{ 'TEMPLATE_EDITOR.DOCUMENT_METADATA.DOCUMENT_NAME_SHORT' | translate }}</mat-label>
          <input [(ngModel)]="template.name" matInput required type="text" />
        </mat-form-field>
        <mat-form-field class="col-3">
          <mat-label>{{ 'TEMPLATE_EDITOR.DOCUMENT_METADATA.DOCUMENT_VERSION' | translate }}</mat-label>
          <input [(ngModel)]="template.documentVersion" matInput required type="text" />
        </mat-form-field>
      </div>
      <div class="row gap">

        <mat-form-field class="col-4">
          <mat-label>{{ 'TEMPLATE_EDITOR.DOCUMENT_METADATA.WORD_TEMPLATE' | translate }}</mat-label>
          <mat-select [(ngModel)]="template.wordTemplate" required>
            <mat-option *ngFor="let wordTemplate of wordTemplates" [value]="wordTemplate">{{wordTemplate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </mat-expansion-panel>
  </mat-accordion>
</div>

<h6>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.HEADING' | translate }}</h6>
<div class="editor-table" cdkDropList (cdkDropListDropped)="drop($event)">

  <div class="editor-header font-s">
    <div></div>
    <div>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.WHAT' | translate }}</div>
    <div>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.WHEN' | translate }}</div>
    <div>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.HOW' | translate }}</div>
    <div>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.WHERE' | translate }}</div>
    <div>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.DOSE' | translate }}</div>
    <div>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.WHO' | translate }}</div>
  </div>

  @for (section of template.sections; track section) {
    <div class="section row font-s" cdkDrag>
      <button class="drag-handle section-edit-buttons" cdkDragHandle mat-icon-button>
        <mat-icon>drag_indicator</mat-icon>
      </button>
      <div class="section-meta">
        <div>{{ 'TEMPLATE_EDITOR.EDITOR_TABLE.SECTION' | translate}} {{ $index + 1 }}</div>
        <div class="section-edit-buttons">
          <button mat-icon-button (click)="toggleEdit($index)">
            <mat-icon class="material-symbols-outlined">edit</mat-icon>
          </button>
          <button mat-icon-button (click)="duplicateSection($index)">
            <mat-icon class="material-symbols-outlined">file_copy</mat-icon>
          </button>
          <button mat-icon-button (click)="deleteSection($index)">
            <mat-icon class="material-symbols-outlined">delete</mat-icon>
          </button>
          <button mat-icon-button (click)="addSection($index)">
            <mat-icon class="material-symbols-outlined">add</mat-icon>
          </button>
        </div>
      </div>

      <div class="section-content">
        @if ($index === editIndex) {
          <hpm-section-edit [section]="section"></hpm-section-edit>
        } @else {

          @if (section.heading) {
            <div class="heading" [ngClass]="!section.headingValid ? 'invalid' : ''" [ngStyle]="{'font-size.px': 24 - ((section.headingLevel-1) * 2) }">
              {{ section.heading }}
            </div>
          }

          @if (section.introductionText) {
            <div class="introductionText" [ngClass]="!section.introductionTextValid ? 'invalid' : ''">{{ section.introductionText }}</div>
          }

          @if (section.conditionExpression) {
            <div class="condition" [ngClass]="!section.conditionValid ? 'invalid' : '' ">{{ section.conditionExpression }}</div>
          }
          <hpm-instruction-table [instructions]="section.instructions"></hpm-instruction-table>
        }
      </div>
      <div *cdkDragPreview>
        <!--      we do not want a preview as it is kinda messy-->
      </div>
    </div>
    <div class="ruler"></div>
  }
  @if(!template.sections || template.sections.length === 0) {
    <button mat-icon-button (click)="addSection(0)">
      <mat-icon class="material-symbols-outlined">add</mat-icon>
    </button>
  }
</div>
